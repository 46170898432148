<template>
  <div class="page">
    <header class="header">微拍预展平台</header>
    <div class="main">

    </div>
  </div>
</template>

<style lang='scss' scord>
</style>

<script>
export default {
  data() {
    return {};
  },

  created() {},

  methods: {},
};
</script>